<template>
  <div class="container position-relative">
    <!-- begin::Legacy device alert -->
    <template v-if="equipment.isDeviceLinked() && device.isLegacyDevice()">
      <div class="loading-container loading-overlap m-n5 h-auto">
        <div class="loading-backdrop rounded opacity-20" />
        <div class="loading-block">
          <div class="alert alert-custom alert-light-info ml-auto mr-auto w-100 p-3 d-block">
            <div
              class="alert-text"
              v-html="$t('sunbeds.legacy_device_warning')"
            />
          </div>
        </div>
      </div>
    </template>
    <!-- end::Legacy device alert -->

    <!-- begin::Sunbed management - information -->
    <div class="row position-relative">
      <!-- begin::Loading -->
      <template v-if="isMounted === false">
        <div class="loading-container loading-overlap">
          <div class="loading-backdrop rounded" />
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span class="float-left">Gathering data...</span>
              <span class="spinner spinner-primary ml-auto" />
            </div>
          </div>
        </div>
      </template>
      <!-- end::Loading -->

      <div class="col-12 col-md-8 offset-md-2">
        <div class="row text-center">
          <!-- begin::Lease mode - Information -->
          <div class="col form-group">
            <label class="text-upper">Sunbed management</label>
            <div>
              <span
                :class="[
                  'label w-150px label-inline label-xl label-rounded p-5',
                  (isSunbedManagementActive === true ? 'label-light-info' : 'label-light-success')
                ]"
              >
                {{ isSunbedManagementActive === true ? 'Enabled' : 'Disabled' }}
              </span>
            </div>
          </div>
          <!-- end::Lease mode - Information -->

          <!-- begin::Lease mode - Lock -->
          <div class="col form-group">
            <label class="text-upper">Session lock</label>
            <div>
              <span :class="[
                'label w-150px label-inline label-xl label-rounded p-5',
                (isSunbedManagementLockActive === true ? 'label-light-warning' : 'label-light-success')
              ]">
                {{ isSunbedManagementLockActive === true ? 'Enabled' : 'Disabled' }}
              </span>
            </div>
          </div>
          <!-- end::Lease mode - Lock -->
        </div>
      </div>
    </div>
    <!-- end::Sunbed management - Information -->

    <!-- begin::Sunbed management - Actions -->
    <template v-if="$auth.hasPermission('equipment.lease_mode')">
      <div class="row mt-3 border-top pt-3 border-bottom pb-3">
        <div class="col-12 col-md-9">
          <h5 class="overflow-ellipsis">Sunbed management</h5>
          <span>When enabled, the sunbed can be locked remotely.</span>
        </div>
        <div class="col mt-3 mt-md-auto mb-md-auto text-right">
          <span :class="['switch', { 'switch-success': isSunbedManagementActive === true }]">
            <label class="ml-auto">
              <input
                type="checkbox"
                name="select"
                :checked="isSunbedManagementActive === true"
                :disabled="isLoadingForm"
                @click.prevent="onSunbedManagementChange($event.target.checked)"
              >
              <span />
            </label>
          </span>
        </div>
      </div>

      <div class="row mt-3 border-bottom pb-3">
        <div class="col-12 col-md-9">
          <h5 class="overflow-ellipsis">Session lock <span class="label label-sm label-danger label-inline">Caution</span></h5>
          <span>When enabled, the sunbed won’t be able to run sessions. <strong>Use with caution.</strong></span>
        </div>
        <div class="col mt-3 mt-md-auto mb-md-auto text-right">
          <span :class="['switch', { 'switch-warning': isSunbedManagementLockActive === true }]">
            <label class="ml-auto">
              <input
                type="checkbox"
                name="select"
                :checked="isSunbedManagementLockActive === true"
                :disabled="isLoadingForm"
                @click.prevent="onSunbedManagementLockChange($event.target.checked)"
              >
              <span />
            </label>
          </span>
        </div>
      </div>
    </template>
    <!-- end::Sunbed management - Actions -->
  </div>
</template>

<script>
import Equipment from '@/libs/classes/equipment';

export default {
  data () {
    return {
      isMounted: false,
      isLoading: false,

      isLoadingForm: false,
    };
  },

  computed: {
    equipment () {
      return this.$store.get('sunbeds:view:equipment');
    },
    device () {
      return this.$store.get('sunbeds:view:device');
    },

    leaseModeSetting () {
      if (!this.equipment || !this.equipment.settings) {
        return undefined;
      }

      const leaseModeSetting = this.equipment.settings.find((row) => row.key === 'lease_mode');
      return (leaseModeSetting && leaseModeSetting.payload) || undefined;
    },

    isSunbedManagementActive () {
      return (this.leaseModeSetting && this.leaseModeSetting.enabled === true) || false;
    },
    isSunbedManagementLockActive () {
      return (this.leaseModeSetting && this.leaseModeSetting.lock === true) || false;
    },
  },

  async mounted () {
    await this.$nextTick();

    this.$set(this, 'isMounted', true);
  },

  methods: {
    async onSunbedManagementChange (value = null) {
      if (typeof value !== 'boolean') {
        return;
      }

      this.$set(this, 'isLoadingForm', true);
      try {
        const response = await this.$ws.put(`${Equipment.uri}/${this.equipment._meta.guid}/lease_mode`, {
          body: {
            enabled: value,
            lock: this.isSunbedManagementLockActive,
          },
        });

        this.$eventhub.emit(`document:${response._meta.index}:${response._meta.guid}:update`, response);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },

    async onSunbedManagementLockChange (value = null) {
      if (typeof value !== 'boolean') {
        return;
      }

      if (value === true) {
        const vm = this;
        vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
          title: 'Enable session lock',
          message: 'Are you sure that you want enable the session lock?',
          variant: 'warning',
          buttonText: 'I\'m sure',
          async onSubmit () {
            try {
              const response = await vm.$ws.put(`${Equipment.uri}/${vm.equipment._meta.guid}/lease_mode`, {
                body: {
                  enabled: true,
                  lock: true,
                },
              });

              vm.$eventhub.emit(`document:${response._meta.index}:${response._meta.guid}:update`, response);
            } catch (e) {
              vm.$errors.handle(e, { ui_element: true });
              console.error(e);
            } finally {
              vm.$set(vm, 'isLoadingForm', false);
            }
          },
        });
        return;
      }

      this.$set(this, 'isLoadingForm', true);
      try {
        const response = await this.$ws.put(`${Equipment.uri}/${this.equipment._meta.guid}/lease_mode`, {
          body: {
            enabled: this.isSunbedManagementActive,
            lock: false,
          },
        });

        this.$eventhub.emit(`document:${response._meta.index}:${response._meta.guid}:update`, response);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
};
</script>
